import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';

const app = createApp(App)
import axios from 'axios'
app.config.globalProperties.$http = axios;
app.use(store).use(router).mount('#app')
