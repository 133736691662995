<template>
  <div class="home">
    <div></div>
<!--    <img @click="requestall" alt="Vue logo" src="../assets/logo.png">-->
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
// import {alldata} from "@/api/help/index.js"
// import {onMounted, reactive} from "vue";
export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  // setup(){
  //   const datas = reactive({
  //     value:[],
  //   })
  //   const methods = {
  //     // requestall(){
  //     //   const data={
  //     //     phone:18177572423,
  //     //     type:1,
  //     //   };
  //     //   alldata(data).then(res => {
  //     //     datas.value = []
  //     //     console.log(res);
  //     //     document.title = 'Title'
  //     //   }).catch(err => {
  //     //     console.log(err)
  //     //   })
  //     // }
  //   }
  //   onMounted(()=>{
  //     // methods.requestall()
  //   })
  //   return{
  //     ...methods
  //   }
  // }
}
</script>
